import React, { useEffect, useMemo, useRef, useState } from "react";
import * as THREE from "three";
import Image from "gatsby-image/withIEPolyfill";
import { Canvas, useFrame } from "react-three-fiber";
import { Parallax } from "react-scroll-parallax";
import { Link as ScrollLink } from "react-scroll";

import {
  AspectRatio,
  Box,
  Button,
  Card,
  Flex,
  Heading,
  HStack,
  Icon,
  Inline,
  Paragraph,
  VStack,
  Wrapper,
} from "components";

function Cube(props) {
  const geom = useMemo(() => new THREE.BoxGeometry(1, 1, 1));
  const edges = useMemo(() => new THREE.EdgesGeometry(geom));
  const mat = useMemo(
    () => new THREE.LineBasicMaterial({ color: "#61B9AE", linewidth: 2 })
  );
  // This reference will give us direct access to the mesh
  const mesh = useRef();

  // Rotate mesh every frame, this is outside of React without overhead
  useFrame(() => {
    mesh.current.rotation.y = mesh.current.rotation.y += 0.005;
    mesh.current.rotation.x = mesh.current.rotation.x += 0.005;
  });

  return (
    <mesh {...props} ref={mesh} scale={[3.7, 3.7, 3.7]}>
      <lineSegments attach="geometry" args={[edges, mat]} />
    </mesh>
  );
}

function EventHero({
  title,
  image,
  start,
  end,
  excerpt,
  about_title,
  schedule_title,
  requirements_title,
  standings_title,
}) {
  const [showCube, setShowCube] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowCube(true);
    }, 400);
  }, []);

  return (
    <Box position="relative" display="flex" flexDirection="column" width="100%">
      {/* Hero Image */}
      {image && image.fluid && (
        <Image
          fluid={image.fluid}
          alt={image.alt}
          objectFit="cover"
          objectPosition="50% 50%"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100vh",
          }}
          css={{
            "::after": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              display: "block",
              width: "100%",
              height: "100vh",
              background: "linear-gradient(rgba(0,0,0,0.25), #2c2927)",
            },
          }}
        />
      )}
      <Wrapper id="hero" overflow="visible">
        <Box position="relative" width="100%" overflow="hidden">
          {!showCube && (
            <AspectRatio ratio={{ _: "2:3", lg: "4:3" }} overflow="visible" />
          )}
          {showCube && (
            <Parallax y={[-100, 67]}>
              <AspectRatio ratio={{ _: "2:3", lg: "4:3" }} overflow="visible">
                {/* DESKTOP & TABLET */}
                <Box
                  position="absolute"
                  top="50%"
                  left="50%"
                  height="100vw"
                  width="100vh"
                  opacity={0.5}
                  display={["none", "none", "block"]}
                  css={{ transform: "translate(-50%, -50%)" }}
                >
                  <Canvas
                    orthographic
                    camera={{ position: [100, 0, 15], zoom: 100 }}
                  >
                    <ambientLight />
                    <Cube position={[-1.2, 0, 0]} />
                  </Canvas>
                </Box>
                {/* MOBILE */}
                <Box
                  position="absolute"
                  top="50%"
                  left="50%"
                  height="100vw"
                  width="100vh"
                  opacity={0.5}
                  display={["block", "block", "none"]}
                  css={{ transform: "translate(-50%, -50%)" }}
                >
                  <Canvas
                    orthographic
                    camera={{ position: [100, 0, 15], zoom: 60 }}
                  >
                    <ambientLight />
                    <Cube position={[-1.2, 0, 0]} />
                  </Canvas>
                </Box>
              </AspectRatio>
            </Parallax>
          )}
          <Box position="absolute" top={0} left={0} width="100%">
            <AspectRatio ratio={{ _: "2:3", lg: "4:3" }} overflow="visible">
              <Flex position="relative" alignItems="center" height="100%">
                <Box position="relative" width="100%">
                  <VStack align="center" space="layout.2">
                    <Heading
                      children={title.text}
                      size={900}
                      textAlign="center"
                    />
                    <Card
                      py="spacing.3"
                      px="layout.1"
                      borderRadius={3}
                      bg="fill.0"
                    >
                      <HStack align="center" space="spacing.4">
                        <Box>
                          <Heading children={start} as="span" size={400} />
                        </Box>
                        <Icon
                          symbol="arrow-right-line"
                          size={20}
                          color="text.2"
                        />
                        <Box>
                          <Heading children={end} as="span" size={400} />
                        </Box>
                      </HStack>
                    </Card>
                    <Paragraph
                      children={excerpt}
                      size={600}
                      maxWidth={448}
                      textAlign="center"
                      color="text.1"
                    />
                    <Inline align="center" space="spacing.3">
                      {about_title && about_title.text && (
                        <ScrollLink to="details" smooth={true} offset={-16}>
                          <Button
                            children="Details"
                            as="div"
                            intent="default"
                            appearance="default"
                            height={32}
                          />
                        </ScrollLink>
                      )}
                      {schedule_title && schedule_title.text && (
                        <ScrollLink to="schedule" smooth={true} offset={-16}>
                          <Button
                            children="Schedule"
                            as="div"
                            intent="default"
                            appearance="default"
                            height={32}
                          />
                        </ScrollLink>
                      )}
                      {requirements_title && requirements_title.text && (
                        <ScrollLink to="equipment" smooth={true} offset={-16}>
                          <Button
                            children="Requirements"
                            as="div"
                            intent="default"
                            appearance="default"
                            height={32}
                          />
                        </ScrollLink>
                      )}
                      {standings_title && standings_title.text && (
                        <ScrollLink to="standings" smooth={true} offset={-16}>
                          <Button
                            children="Standings"
                            as="div"
                            intent="default"
                            appearance="default"
                            height={32}
                          />
                        </ScrollLink>
                      )}
                    </Inline>
                  </VStack>
                </Box>
              </Flex>
            </AspectRatio>
          </Box>
        </Box>
      </Wrapper>
    </Box>
  );
}

export default EventHero;
