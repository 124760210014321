import React from "react";

import {
  Box,
  Button,
  Card,
  ColorMode,
  Column,
  Columns,
  Divider,
  Heading,
  Link,
  Paragraph,
  RichText,
  VStack,
  Wrapper,
} from "components";

const EventSponsors = ({ sponsors }) => (
  <>
    {sponsors && sponsors[0] && sponsors[0].logo && sponsors[0].logo.url && (
      <Wrapper id="details" position="relative" mb="layout.1">
        <ColorMode mode="light">
          <Card
            p={{ _: "layout.1", md: "layout.3", lg: "layout.5" }}
            borderRadius={3}
            bg="background.0"
          >
            <VStack space="layout.1">
              {/* Title and Subtitle */}
              <Heading
                children={"Sponsors"}
                as="h2"
                size={700}
                color="text.0"
              />
              {/* Information Items */}

              <Columns space={{ _: "layout.1", md: "layout.3" }}>
                {sponsors.map((sponsor, index) => {
                  if (sponsor.logo && sponsor.logo.url) {
                    return (
                      <Column
                        width={{ _: 1 / 1, md: 1 / 2 }}
                        key={"aboutInformationItem" + index}
                      >
                        <VStack space="spacing.4">
                          <img
                            src={sponsor.logo.url}
                            width="160px"
                            height="auto"
                          />
                          <Heading
                            children={sponsor.name}
                            as="h3"
                            size={100}
                            color="text.0"
                          />
                        </VStack>
                      </Column>
                    );
                  } else {
                    return null;
                  }
                })}
              </Columns>
            </VStack>
          </Card>
        </ColorMode>
      </Wrapper>
    )}
  </>
);

export default EventSponsors;
