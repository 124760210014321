import React from "react";
import moment from "moment-timezone";

import { Box, Flex, Heading, HStack } from "components";

const SIZE = 32;
const STROKE = 2;
const COLOR = "text.1";

function Date({ time }) {
  return (
    <HStack align="center" space="spacing.3">
      <Box width={SIZE} height={SIZE}>
        <Flex
          position="relative"
          width="100%"
          height="100%"
          borderWidth={STROKE}
          borderColor={COLOR}
          borderRadius={3}
        >
          <Box
            position="absolute"
            top="spacing.2"
            left={0}
            width="100%"
            height={STROKE}
            bg={COLOR}
          />
          <Heading
            children={moment(time)
              .tz("America/Edmonton")
              .format("DD")}
            as="span"
            size={300}
            m="auto"
            pt="spacing.2"
            textAlign="center"
            color={COLOR}
          />
        </Flex>
      </Box>
      <Heading
        children={moment(time)
          .tz("America/Edmonton")
          .format("MMMM YYYY")}
        as="span"
        size={400}
        color={COLOR}
      />
    </HStack>
  );
}

export default Date;
