import React from "react";

import {
  Box,
  Column,
  Columns,
  Heading,
  RichText,
  VStack,
  Wrapper,
} from "components";

import ChallengeItem from "./ChallengeItem";

const HomeCharity = ({ homeData, challenges }) => (
  <>
    <Box position="relative" mt={-256} mb="layout.1">
      {/* LINES */}
      <VStack space="layout.1">
        <Box height="4px" width="100%" bg="tint.teal" />
        <Box height="4px" width="100%" bg="tint.teal" />
        <Box height="4px" width="100%" bg="tint.teal" />
      </VStack>
    </Box>
    {/* Charity Section */}
    <Wrapper
      position="relative"
      py={{ _: "layout.7", lg: "layout.7" }}
      bg="tint.teal"
    >
      <Box pb={128} />
      <VStack space="layout.5">
        <VStack space="layout.1">
          {homeData.charity_title && (
            <Heading as="h2" size={900}>
              {homeData.charity_title.text}
            </Heading>
          )}
          {homeData.charity_body && homeData.charity_body.html && (
            <RichText
              html={homeData.charity_body.html}
              size={600}
              color="text.0"
            />
          )}
        </VStack>
        {/* Challenges */}
        <Columns space="layout.1">
          {challenges.map((challenge, index) => (
            <Column
              width={{ _: 1 / 1, md: 1 / 4 }}
              key={"homePageChallenge" + index}
            >
              <ChallengeItem {...challenge} />
            </Column>
          ))}
        </Columns>
      </VStack>
    </Wrapper>
  </>
);

export default HomeCharity;
