import React from "react";
import moment from "moment";
import { Link } from "gatsby";

import { Box, Card, ColorMode, Heading, Text, VStack } from "components";

const Cube = ({ size, ...rest }) => (
  <Box
    as="svg"
    width={size}
    height={size}
    viewBox="0 0 87 87"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <g opacity="0.66">
      <rect
        x="32.9963"
        y="13.1041"
        width="55.6132"
        height="50.3222"
        transform="rotate(28.4549 32.9963 13.1041)"
        stroke="currentColor"
      />
      <rect
        x="29.4999"
        y="0.677891"
        width="55.0841"
        height="49.7931"
        transform="rotate(28.4549 29.4999 0.677891)"
        stroke="currentColor"
      />
      <line
        x1="32.6547"
        y1="13.0533"
        x2="28.8193"
        y2="0.142392"
        stroke="currentColor"
      />
      <line
        x1="81.9633"
        y1="39.7756"
        x2="78.1279"
        y2="26.8647"
        stroke="currentColor"
      />
      <line
        x1="58.0141"
        y1="83.9673"
        x2="54.1787"
        y2="71.0564"
        stroke="currentColor"
      />
      <line
        x1="8.70552"
        y1="57.2451"
        x2="4.87008"
        y2="44.3342"
        stroke="currentColor"
      />
    </g>
  </Box>
);

const EventCard = ({ event }) => (
  <ColorMode mode="light">
    <Link to={"/event/" + event.uid + "/"}>
      <Card px="layout.2" py="layout.2" borderRadius={3} bg="background.0">
        <VStack space="layout.1">
          <Cube color="tint.teal" size={64} />
          {event.document.data.start && (
            <Heading as="span" size={500} color="tint.orange">
              {moment(event.document.data.start).format("MMMM DD")}
            </Heading>
          )}
          {event.document.data.title && (
            <Heading as="span" size={700} minHeight={{ _: 40 * 2, lg: 40 * 3 }}>
              {event.document.data.title.text}
            </Heading>
          )}
          {event.document.data.excerpt && (
            <Text size={400} color="text.1">
              {event.document.data.excerpt}
            </Text>
          )}
          {event.document.data.sponsors &&
            event.document.data.sponsors[0] &&
            event.document.data.sponsors[0].name && (
              <Heading as="span" size={100} color="text.2">
                Sponsored by {event.document.data.sponsors[0].name}
              </Heading>
            )}
        </VStack>
      </Card>
    </Link>
  </ColorMode>
);

export default EventCard;
