import React, { useEffect, useMemo, useRef, useState } from "react";
import * as THREE from "three";
import Image from "gatsby-image/withIEPolyfill";
import { Canvas, useFrame } from "react-three-fiber";
import { Parallax } from "react-scroll-parallax";
import { css } from "@emotion/core";

import {
  AspectRatio,
  Box,
  Heading,
  Paragraph,
  Text,
  VStack,
  Wrapper,
} from "components";

function Cube(props) {
  const geom = useMemo(() => new THREE.BoxGeometry(1, 1, 1));
  const edges = useMemo(() => new THREE.EdgesGeometry(geom));
  const mat = useMemo(
    () => new THREE.LineBasicMaterial({ color: "#61B9AE", linewidth: 2 })
  );
  // This reference will give us direct access to the mesh
  const mesh = useRef();

  // Rotate mesh every frame, this is outside of React without overhead
  useFrame(() => {
    mesh.current.rotation.y = mesh.current.rotation.y += 0.005;
    mesh.current.rotation.x = mesh.current.rotation.x += 0.005;
  });

  return (
    <mesh {...props} ref={mesh} scale={[3.7, 3.7, 3.7]}>
      <lineSegments attach="geometry" args={[edges, mat]} />
    </mesh>
  );
}

function HomeHero({ homeData }) {
  const [showCube, setShowCube] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowCube(true);
    }, 400);
  }, []);
  return (
    <Box position="relative" display="flex" flexDirection="column" width="100%">
      {/* Hero Image */}
      {homeData.hero_image && homeData.hero_image.fluid && (
        <Image
          fluid={homeData.hero_image.fluid}
          alt={homeData.hero_image.alt}
          objectFit="cover"
          objectPosition="50% 50%"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100vh",
          }}
          css={{
            "::after": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              display: "block",
              width: "100%",
              height: "100vh",
              background: "linear-gradient(rgba(0,0,0,0.25), #2c2927)",
            },
          }}
        />
      )}
      <Wrapper
        my={{ _: "layout.6", lg: "layout.7" }}
        pt={{ _: "layout.3", lg: "layout.4" }}
        overflow="visible"
      >
        <Box position="relative">
          {/* DESKTOP & TABLET */}
          <Box
            className="desktopTablet"
            position="absolute"
            top={0}
            right={{ _: "-20%", lg: "-10%" }}
            display={{ _: "none", lg: "block" }}
            width={{ _: 1 / 1, lg: 2 / 3 }}
          >
            {!showCube && <AspectRatio ratio="1:1" />}
            {showCube && (
              <Parallax y={[-100, 67]}>
                <AspectRatio ratio="1:1">
                  <Canvas
                    orthographic
                    camera={{ position: [100, 0, 15], zoom: 90 }}
                  >
                    <ambientLight />
                    <Cube position={[-1.2, 0, 0]} />
                  </Canvas>
                </AspectRatio>
              </Parallax>
            )}
          </Box>
          {/* MOBILE */}
          <Box
            className="mobile"
            position="absolute"
            top={0}
            right={0}
            display={{ _: "block", lg: "none" }}
            width={1 / 1}
          >
            {!showCube && <AspectRatio ratio="1:1" />}
            {showCube && (
              <Parallax y={[-128, 67]}>
                <AspectRatio ratio="1:1">
                  <Canvas
                    orthographic
                    camera={{ position: [100, 0, 15], zoom: 50 }}
                  >
                    <ambientLight />
                    <Cube position={[0, 0, 0]} />
                  </Canvas>
                </AspectRatio>
              </Parallax>
            )}
          </Box>
          <VStack space="layout.3">
            <VStack space="spacing.3">
              {homeData.title_caption && (
                <Heading
                  children={homeData.title_caption}
                  as="span"
                  size={100}
                />
              )}
              <Heading
                dangerouslySetInnerHTML={{ __html: homeData.title.html }}
                as="h1"
                size={900}
                css={css({
                  whiteSpace: "pre-line",
                  textTransform: "uppercase",
                  em: {
                    fontStyle: "initial",
                    fontWeight: "inherit",
                    color: "#de5829",
                  },
                  strong: {
                    fontWeight: "inherit",
                    color: "#61b9ae",
                  },
                })}
                position="relative"
              />
            </VStack>
            <VStack space="spacing.3">
              {homeData.subtitle && (
                <Heading
                  children={homeData.subtitle}
                  as="h2"
                  size={600}
                  position="relative"
                />
              )}
              {homeData.subtitle_caption && (
                <Paragraph
                  children={homeData.subtitle_caption}
                  as="span"
                  size={500}
                  position="relative"
                />
              )}
            </VStack>
          </VStack>
        </Box>
      </Wrapper>
    </Box>
  );
}

export default HomeHero;
