import React from "react";
import moment from "moment-timezone";

import { Box, Heading, HStack } from "components";

const SIZE = 32;
const STROKE = 2;
const COLOR = "text.1";

function Hand({ gap, degree }) {
  return (
    <Box
      position="absolute"
      left={0}
      width={SIZE / 2 - STROKE - gap}
      height={0}
      css={{ transformOrigin: "0% 0%" }}
      style={{ transform: `rotate(${degree}deg)` }}
    >
      <Box
        width="100%"
        height={STROKE}
        bg={COLOR}
        css={{ transform: "translateY(-50%)" }}
      />
    </Box>
  );
}

function Clock({ time }) {
  return (
    <HStack align="center" space="spacing.3">
      <Box width={SIZE} height={SIZE}>
        <Box
          position="relative"
          width="100%"
          height="100%"
          borderWidth={STROKE}
          borderColor={COLOR}
          borderRadius="50%"
        >
          <Box
            position="absolute"
            top="50%"
            left="50%"
            css={{ transform: "translate(-50%, -50%) rotate(-90deg)" }}
          >
            {/* Hour hand */}
            <Hand
              gap={6}
              degree={
                (moment(time)
                  .tz("America/Edmonton")
                  .hour() /
                  12) *
                360
              }
            />
            {/* Minute hand */}
            <Hand
              gap={2}
              degree={
                (moment(time)
                  .tz("America/Edmonton")
                  .minute() /
                  60) *
                360
              }
            />
          </Box>
        </Box>
      </Box>
      <Heading
        children={
          moment(time)
            .tz("America/Edmonton")
            .format("h:mm a") + " (MDT)"
        }
        as="span"
        size={400}
        color={COLOR}
      />
    </HStack>
  );
}

export default Clock;
