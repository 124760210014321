import React from "react";

import {
  Box,
  Button,
  Card,
  ColorMode,
  Column,
  Columns,
  Divider,
  Heading,
  Link,
  Paragraph,
  RichText,
  VStack,
  Wrapper,
} from "components";

const EventAbout = ({
  about_title,
  about_body,
  about_information,
  about_buttons,
}) => (
  <>
    {about_title && about_title.text && (
      <Wrapper id="details" position="relative" mb="layout.1">
        <ColorMode mode="light">
          <Card
            p={{ _: "layout.1", md: "layout.3", lg: "layout.5" }}
            borderRadius={3}
            bg="background.0"
          >
            <VStack space="layout.1">
              {/* Title and Subtitle */}
              {about_title && about_title.text && (
                <Heading
                  children={about_title.text}
                  as="h2"
                  size={700}
                  color="text.0"
                />
              )}
              <Columns space="layout.3">
                <Column width={{ _: 1 / 1, lg: 2 / 3 }}>
                  <VStack space="layout.3">
                    {about_body && about_body.html && (
                      <RichText
                        html={about_body.html}
                        size={500}
                        color="text.1"
                      />
                    )}
                  </VStack>
                </Column>
                <Column width={{ _: 1 / 1, lg: 1 / 3 }}>
                  {/* Information Buttons */}
                  {about_buttons && about_buttons[0] && (
                    <VStack as="ul" space="spacing.3">
                      {about_buttons.map((button, index) => {
                        if (button.label && button.link && button.link.url) {
                          if (index === 0)
                            return (
                              <Button
                                as={Link}
                                href={button.link && button.link.url}
                                children={button.label}
                                intent="secondary"
                                appearance="primary"
                                width="100%"
                                key={"eventInformationButton" + index}
                              />
                            );
                          else
                            return (
                              <Button
                                as={Link}
                                href={button.link && button.link.url}
                                children={button.label}
                                intent="default"
                                appearance="default"
                                width="100%"
                                key={"eventInformationButton" + index}
                              />
                            );
                        }
                      })}
                    </VStack>
                  )}
                </Column>
              </Columns>
              <Box py="layout.1">
                <Divider />
              </Box>
              {/* Information Items */}
              {about_information && about_information[0] && (
                <Columns space={{ _: "layout.1", md: "layout.3" }}>
                  {about_information.map((item, index) => (
                    <Column
                      width={{ _: 1 / 1, md: 1 / 2 }}
                      key={"aboutInformationItem" + index}
                    >
                      <Heading
                        children={item.label}
                        as="h3"
                        size={100}
                        color="text.0"
                      />
                      <Paragraph
                        children={item.value}
                        size={500}
                        pt="spacing.2"
                        color="text.1"
                      />
                    </Column>
                  ))}
                </Columns>
              )}
            </VStack>
          </Card>
        </ColorMode>
      </Wrapper>
    )}
  </>
);

export default EventAbout;
