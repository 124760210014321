import React, { useState } from "react";
import { keyframes } from "@emotion/core";
import css from "@styled-system/css";

import {
  AspectRatio,
  Box,
  Button,
  Card,
  ColorMode,
  Flex,
  Heading,
  HStack,
  Icon,
  Link,
  RichText,
  Text,
  VStack,
} from "components";

const DURATION = 160;
const LOOP = keyframes`
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  100% {
    -webkit-transform: translate3d(-33.33333333%, 0, 0);
    transform: translate3d(-33.33333333%, 0, 0);
  }
`;

// The React component for this section
function HomeCompanies({ homeData, companies }) {
  const [search, setSearch] = useState("");

  return (
    <>
      {companies && companies[0] && (
        <Box position="relative" width="100%" overflow="hidden">
          <ColorMode mode="dark">
            {/* Companies */}

            <AspectRatio
              ratio={{ _: "1:1", md: "16:9", lg: "4:3", xlg: "16:9" }}
            >
              <Flex height="100%" opacity={0.5} overflow="hidden">
                {/* Row 1 */}
                <Box
                  position="absolute"
                  css={{
                    animationIterationCount: "infinite",
                    animationTimingFunction: "linear",
                    animationName: LOOP,
                    animationDuration: DURATION * 1.5 + "s",
                  }}
                  top={0}
                >
                  <Flex as="ul" alignItems="center">
                    {companies
                      .slice(1, companies.length / 3)
                      .map(({ data }, index) => (
                        <Heading
                          children={data.Name}
                          as="li"
                          size={700}
                          px="layout.1"
                          textAlign="center"
                          minWidth={[196, 256]}
                          key={"companies1" + index}
                        />
                      ))}
                    {companies
                      .slice(1, companies.length / 3)
                      .map(({ data }, index) => (
                        <Heading
                          children={data.Name}
                          as="li"
                          size={700}
                          px="layout.1"
                          textAlign="center"
                          key={"companies2" + index}
                        />
                      ))}
                    {companies
                      .slice(1, companies.length / 3)
                      .map(({ data }, index) => (
                        <Heading
                          children={data.Name}
                          as="li"
                          size={700}
                          px="layout.1"
                          textAlign="center"
                          key={"companies3" + index}
                        />
                      ))}
                  </Flex>
                </Box>
                {/* Row 2 */}
                <Box
                  position="absolute"
                  css={{
                    animationIterationCount: "infinite",
                    animationTimingFunction: "linear",
                    animationName: LOOP,
                    animationDuration: DURATION * 1 + "s",
                  }}
                  top="40%"
                >
                  <Flex as="ul" alignItems="center">
                    {companies
                      .slice(companies.length / 3, (companies.length / 3) * 2)
                      .map(({ data }, index) => (
                        <Heading
                          children={data.Name}
                          as="li"
                          size={700}
                          px="layout.1"
                          textAlign="center"
                          minWidth={[196, 256]}
                          key={"companies1" + index}
                        />
                      ))}
                    {companies
                      .slice(companies.length / 3, (companies.length / 3) * 2)
                      .map(({ data }, index) => (
                        <Heading
                          children={data.Name}
                          as="li"
                          size={700}
                          px="layout.1"
                          textAlign="center"
                          minWidth={[196, 256]}
                          key={"companies2" + index}
                        />
                      ))}
                    {companies
                      .slice(companies.length / 3, (companies.length / 3) * 2)
                      .map(({ data }, index) => (
                        <Heading
                          children={data.Name}
                          as="li"
                          size={700}
                          px="layout.1"
                          textAlign="center"
                          minWidth={[196, 256]}
                          key={"companies3" + index}
                        />
                      ))}
                  </Flex>
                </Box>
                {/* Row 3 */}
                <Box
                  position="absolute"
                  css={{
                    animationIterationCount: "infinite",
                    animationTimingFunction: "linear",
                    animationName: LOOP,
                    animationDuration: DURATION * 1.2 + "s",
                  }}
                  top="80%"
                >
                  <Flex as="ul" alignItems="center">
                    {companies
                      .slice((companies.length / 3) * 2, companies.length)
                      .map(({ data }, index) => (
                        <Heading
                          children={data.Name}
                          as="li"
                          size={700}
                          px="layout.1"
                          textAlign="center"
                          minWidth={[196, 256]}
                          key={"companies1" + index}
                        />
                      ))}
                    {companies
                      .slice((companies.length / 3) * 2, companies.length)
                      .map(({ data }, index) => (
                        <Heading
                          children={data.Name}
                          as="li"
                          size={700}
                          px="layout.1"
                          textAlign="center"
                          minWidth={[196, 256]}
                          key={"companies2" + index}
                        />
                      ))}
                    {companies
                      .slice((companies.length / 3) * 2, companies.length)
                      .map(({ data }, index) => (
                        <Heading
                          children={data.Name}
                          as="li"
                          size={700}
                          px="layout.1"
                          textAlign="center"
                          minWidth={[196, 256]}
                          key={"companies3" + index}
                        />
                      ))}
                  </Flex>
                </Box>
              </Flex>
            </AspectRatio>
          </ColorMode>
          {/* Title and Button */}
          <ColorMode mode="light">
            <Card
              position={{ _: "relative", lg: "absolute" }}
              left={{ _: null, lg: "50%" }}
              top={{ _: null, lg: "50%" }}
              m={{ _: 20, md: 32, lg: 0 }}
              p={{ _: "layout.2", md: "layout.3", lg: "layout.5" }}
              borderRadius={3}
              bg="background.0"
              css={css({
                transform: [
                  "initial",
                  "initial",
                  "initial",
                  "translate(-50%, -50%)",
                ],
              })}
            >
              <VStack space="layout.2">
                {homeData.registration_title &&
                  homeData.registration_title.text && (
                    <Heading
                      children={homeData.registration_title.text}
                      as="h2"
                      size={700}
                      textAlign="center"
                    />
                  )}
                <Box
                  width="100%"
                  p="layout.1"
                  borderRadius={10}
                  bg="background.2"
                >
                  <Box position="relative">
                    <Text
                      as="input"
                      type="text"
                      placeholder="Search for your company..."
                      value={search}
                      size={500}
                      width="100%"
                      m={0}
                      px="layout.1"
                      py="spacing.3"
                      borderRadius={5}
                      border={0}
                      bg="fill.0"
                      css={css({
                        appearance: "none",
                        "::placeholder": {
                          color: "text.0",
                          opacity: 0.5,
                        },
                      })}
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                    />
                    <Icon
                      symbol="search-line"
                      size={20}
                      color="text.1"
                      position="absolute"
                      top="spacing.3"
                      right="spacing.3"
                    />
                  </Box>
                  <Flex
                    flexDirection="column"
                    justifyContent="center"
                    height={256}
                  >
                    {!search && (
                      <Box p="layout.2">
                        <VStack space="layout.1" align="center">
                          {homeData.registration_body &&
                            homeData.registration_body.html && (
                              <RichText
                                html={homeData.registration_body.html}
                                size={500}
                                textAlign="center"
                                color="text.0"
                              />
                            )}
                          {homeData.registration_button_text &&
                            homeData.registration_button_link &&
                            homeData.registration_button_link.url && (
                              <Button
                                children={homeData.registration_button_text}
                                as={Link}
                                href={homeData.registration_button_link.url}
                                intent="primary"
                                appearance="primary"
                              />
                            )}
                        </VStack>
                      </Box>
                    )}
                    {search && (
                      <Box py="layout.2">
                        {search &&
                          companies
                            .filter(
                              (company) =>
                                company.data &&
                                company.data.Name &&
                                company.data.Name.toLowerCase().indexOf(
                                  search.toLowerCase()
                                ) !== -1
                            )
                            .slice(0, 7)
                            .map((company, index) => (
                              <HStack
                                space="spacing.3"
                                align="center"
                                key={"companyList" + index + company.data.Name}
                              >
                                <Icon
                                  symbol="checkbox-circle-fill"
                                  size={20}
                                  color="tint.teal"
                                />
                                <Text children={company.data.Name} />
                              </HStack>
                            ))}
                        {search &&
                          companies.filter(
                            (company) =>
                              company.data &&
                              company.data.Name &&
                              company.data.Name.toLowerCase().indexOf(
                                search.toLowerCase()
                              ) !== -1
                          ).length === 0 && (
                            <VStack align="center" space="layout.1">
                              <Text
                                children={homeData.not_registered_prompt}
                                size={500}
                                textAlign="center"
                              />
                              {homeData.registration_button_text &&
                                homeData.registration_button_link &&
                                homeData.registration_button_link.url && (
                                  <Button
                                    children={homeData.registration_button_text}
                                    as={Link}
                                    href={homeData.registration_button_link.url}
                                    intent="primary"
                                    appearance="primary"
                                  />
                                )}
                            </VStack>
                          )}
                      </Box>
                    )}
                  </Flex>
                </Box>
              </VStack>
            </Card>
          </ColorMode>
        </Box>
      )}
    </>
  );
}

export default HomeCompanies;
