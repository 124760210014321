import React from "react";
import { Link } from "gatsby";
import Image from "gatsby-image/withIEPolyfill";

import { Box, Flex, Heading, FundRaisingCard, VStack } from "components";

const Ring = ({ size, progress }) => {
  const circumference = size * 2 * Math.PI;
  const offset = circumference - (progress / 100) * circumference;
  return (
    <Box
      as="svg"
      class="progress-ring"
      width={size * 2 + 16}
      height={size * 2 + 16}
      color="tint.orange"
      style={{ color: "#de5829" }}
    >
      <Box
        as="circle"
        class="progress-ring__circle"
        stroke="currentColor"
        strokeWidth={4}
        fill="white"
        r={size}
        cx={size + 8}
        cy={size + 8}
        css={{
          transition: "stroke-dashoffset 0.35s",
          transform: "rotate(-90deg)",
          transformOrigin: "50% 50%",
        }}
        style={{
          strokeDasharray: `${circumference} ${circumference}`,
          strokeDashoffset: offset,
        }}
      />
    </Box>
  );
};

const ChallengeItem = ({ uid, data }) => (
  <Box height="100%">
    <Link to={"/charity-challenge/" + uid + "/"}>
      <Flex flexDirection="column" justifyContent="space-between" height="100%">
        <VStack space="layout.1">
          <Box position="relative" width={144} mx="auto">
            <Ring size={64} progress={(data.goal_progress / data.goal) * 100} />
            {data.logo && data.logo.fluid && (
              <Box
                position="absolute"
                top={"50%"}
                left={"50%"}
                height="96px"
                width="96px"
                css={{ transform: "translate(-50%, -50%)" }}
              >
                <Image
                  fluid={data.logo.fluid}
                  alt={data.logo.alt}
                  objectFit="contain"
                  style={{ height: "100%", width: "100%" }}
                />
              </Box>
            )}
          </Box>
          {data.title && data.title.text && (
            <Heading children={data.title.text} size={500} textAlign="center" />
          )}
        </VStack>
        <Box mt="layout.1">
          <FundRaisingCard
            progress={data.goal_progress}
            goal={data.goal}
            units={data.goal_units}
          />
        </Box>
      </Flex>
    </Link>
  </Box>
);

export default ChallengeItem;
