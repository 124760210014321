import React from "react";

import {
  Box,
  Card,
  ColorMode,
  Heading,
  RichText,
  VStack,
  Wrapper,
} from "components";

const EventRequirements = ({ requirements_title, requirements_body }) => (
  <>
    {requirements_title && requirements_title.text && (
      <Wrapper id="equipment" position="relative" mb="layout.1">
        <ColorMode mode="light">
          <Card p="layout.5" borderRadius={3} bg="background.0">
            {/* Title and Items */}
            <Heading
              children={requirements_title.text}
              size={700}
              color="text.0"
              mb="layout.2"
            />
            {requirements_body && requirements_body.html && (
              <VStack space={2}>
                <RichText html={requirements_body.html} />
              </VStack>
            )}
          </Card>
        </ColorMode>
      </Wrapper>
    )}
  </>
);

export default EventRequirements;
