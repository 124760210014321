import React from "react";

import {
  Button,
  Card,
  ColorMode,
  Column,
  Columns,
  Heading,
  Link,
  RichText,
  VStack,
  Wrapper,
} from "components";

const ChallengeBody = ({ subtitle, body, buttons }) => (
  <Wrapper position="relative">
    <ColorMode mode="light">
      <Card
        p={{ _: "layout.1", md: "layout.3", lg: "layout.5" }}
        borderRadius={3}
        bg="background.0"
      >
        <VStack space="layout.1">
          {/* Title and Body Text */}
          <Heading children={subtitle.text} as="h2" size={700} />
          <Columns space="layout.3">
            {body && body.html && (
              <Column width={{ _: 1 / 1, lg: 2 / 3 }}>
                <RichText html={body.html} size={500} color="text.1" />
              </Column>
            )}
            <Column width={{ _: 1 / 1, lg: 1 / 3 }}>
              {/* Buttons */}
              {buttons && buttons[0] && (
                <VStack as="ul" space="spacing.3">
                  {buttons.map((button, index) => {
                    if (index === 0)
                      return (
                        <Button
                          as={Link}
                          href={button.link && button.link.url}
                          children={button.label}
                          intent="secondary"
                          appearance="primary"
                          width="100%"
                          key={"challengeBodyButton" + index}
                        />
                      );
                    else
                      return (
                        <Button
                          as={Link}
                          href={button.link && button.link.url}
                          children={button.label}
                          intent="default"
                          appearance="default"
                          width="100%"
                          key={"challengeBodyButton" + index}
                        />
                      );
                  })}
                </VStack>
              )}
            </Column>
          </Columns>
        </VStack>
      </Card>
    </ColorMode>
  </Wrapper>
);

export default ChallengeBody;
