import React, { useState } from "react";

import {
  Box,
  Button,
  Card,
  ColorMode,
  Heading,
  Inline,
  Link,
  VStack,
  Wrapper,
} from "components";

function EventStandings({
  standings_title,
  standings_links,
  standings_buttons,
}) {
  const [activeStandingsLink, setActiveStandingsLink] = useState(0);
  return (
    <>
      {standings_title && standings_title.text && (
        <Wrapper
          id="standings"
          position="relative"
          mb="layout.1"
          overflow="hidden"
        >
          <ColorMode mode="light">
            <Card
              p={{ _: "layout.1", md: "layout.3", lg: "layout.5" }}
              borderRadius={3}
              bg="background.0"
            >
              <VStack space="layout.1">
                <Heading
                  children={standings_title.text}
                  as="h2"
                  size={700}
                  color="text.0"
                />
                {standings_links &&
                  standings_links[0] &&
                  standings_links[0].link && (
                    <Inline space="layout.1">
                      {standings_links.map((item, index) => (
                        <Button
                          children={item.label}
                          appearance="primary"
                          intent={
                            activeStandingsLink === index
                              ? "primary"
                              : "default"
                          }
                          onClick={() => {
                            setActiveStandingsLink(index);
                          }}
                          key={"eventStandingsEmbedButton" + index}
                        />
                      ))}
                    </Inline>
                  )}
                {standings_links &&
                  standings_links[activeStandingsLink] &&
                  standings_links[activeStandingsLink].link &&
                  standings_links[activeStandingsLink].link.url && (
                    <Box pt="layout.1">
                      <iframe
                        src={standings_links[activeStandingsLink].link.url}
                        width="100%"
                        height="800px"
                        frameBorder="none"
                        title="Standings"
                      />
                    </Box>
                  )}
                {/* Information Buttons */}
                {standings_buttons && standings_buttons[0] && (
                  <Inline space="layout.1">
                    {standings_buttons.map((button, index) => (
                      <Box key={"eventStandingsButton" + index}>
                        <Button
                          as={Link}
                          href={button.link && button.link.url}
                          children={button.label}
                          appearance="primary"
                          intent="secondary"
                        />
                      </Box>
                    ))}
                  </Inline>
                )}
              </VStack>
            </Card>
          </ColorMode>
        </Wrapper>
      )}
    </>
  );
}

export default EventStandings;
