import React from "react";
import moment from "moment-timezone";

import {
  Card,
  ColorMode,
  Column,
  Columns,
  Heading,
  VStack,
  Wrapper,
} from "components";

import Clock from "./Clock";
import Date from "./Date";

const EventSchedule = ({ schedule_title, schedule_items }) => (
  <>
    {schedule_title && schedule_title.text && (
      <Wrapper id="schedule" position="relative" mb="layout.1">
        <ColorMode mode="light">
          <Card
            p={{ _: "layout.1", md: "layout.3", lg: "layout.5" }}
            borderRadius={3}
            bg="background.0"
          >
            <VStack space="layout.1">
              <Heading
                children={schedule_title.text}
                as="h2"
                size={700}
                color="text.0"
              />
              <Columns as="ul" space="spacing.3">
                {schedule_items.map((item, index) => (
                  <Column width={{ _: 1 / 1, md: 1 / 2 }}>
                    <Card
                      key={"scheduleItem" + index}
                      p="layout.2"
                      borderRadius={5}
                      bg="fill.0"
                    >
                      <VStack space="layout.1" dividers={true}>
                        <Heading
                          children={item.label}
                          as="h3"
                          size={500}
                          color="text.0"
                        />
                        <VStack space="spacing.3">
                          <Date time={item.time} />
                          <Clock time={item.time} />
                        </VStack>
                      </VStack>
                    </Card>
                  </Column>
                ))}
              </Columns>
            </VStack>
          </Card>
        </ColorMode>
      </Wrapper>
    )}
  </>
);

export default EventSchedule;
