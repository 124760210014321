import React from "react";

import { Box, Column, Columns, VStack, Wrapper } from "components";

import EventCard from "./EventCard";

const SPACE = "spacing.3";

const HomeEvents = ({ homeData }) => {
  const half1 =
    homeData && homeData.events.filter((event, index) => index % 2 === 0);
  const half2 =
    homeData && homeData.events.filter((event, index) => index % 2 === 1);
  const third1 =
    homeData && homeData.events.filter((event, index) => index % 3 === 0);
  const third2 =
    homeData && homeData.events.filter((event, index) => index % 3 === 1);
  const third3 =
    homeData && homeData.events.filter((event, index) => index % 3 === 2);
  return (
    <>
      {homeData && homeData.events && (
        <Wrapper position="relative" zIndex={1}>
          {/* ----- DESKTOP * --------*/}
          <Box display={["none", "none", "none", "flex"]}>
            <Columns space={SPACE}>
              <Column width={1 / 3}>
                <VStack space={SPACE}>
                  {third1.map(({ event }, index) => (
                    <EventCard
                      event={event}
                      key={"homePageEventDesktopThird1" + index}
                    />
                  ))}
                </VStack>
              </Column>
              <Column width={1 / 3} mt={64}>
                <Box mt={64}>
                  <VStack space={SPACE}>
                    {third2.map(({ event }, index) => (
                      <EventCard
                        event={event}
                        key={"homePageEventDesktopThird2" + index}
                      />
                    ))}
                  </VStack>
                </Box>
              </Column>
              <Column width={1 / 3}>
                <Box mt={128}>
                  <VStack space={SPACE}>
                    {third3.map(({ event }, index) => (
                      <EventCard
                        event={event}
                        key={"homePageEventDesktopThird3" + index}
                      />
                    ))}
                  </VStack>
                </Box>
              </Column>
            </Columns>
          </Box>
          {/* ----- DESKTOP * --------*/}
          <Box display={["none", "none", "flex", "none"]}>
            <Columns space={SPACE}>
              <Column width={1 / 2}>
                <VStack space={SPACE}>
                  {half1.map(({ event }, index) => (
                    <EventCard
                      event={event}
                      key={"homePageEventDesktopHalf1" + index}
                    />
                  ))}
                </VStack>
              </Column>
              <Column width={1 / 2}>
                <Box mt={64}>
                  <VStack space={SPACE}>
                    {half2.map(({ event }, index) => (
                      <EventCard
                        event={event}
                        key={"homePageEventDesktopHalf2" + index}
                      />
                    ))}
                  </VStack>
                </Box>
              </Column>
            </Columns>
          </Box>
          {/* ----- Mobile * --------*/}
          <Box display={["flex", "flex", "none"]}>
            <VStack space={SPACE}>
              {homeData.events.map(({ event }, index) => (
                <EventCard event={event} key={"homePageEventMobile" + index} />
              ))}
            </VStack>
          </Box>
        </Wrapper>
      )}
    </>
  );
};

export default HomeEvents;
