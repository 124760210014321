import React from "react";
import Image from "gatsby-image/withIEPolyfill";

import {
  AspectRatio,
  Box,
  Heading,
  FundRaisingCard,
  Paragraph,
  VStack,
  Wrapper,
} from "components";

const Ring = ({ size, progress }) => {
  const circumference = size * 2 * Math.PI;
  const offset = circumference - (progress / 100) * circumference;
  return (
    <Box
      as="svg"
      class="progress-ring"
      width={size * 2}
      height={size * 2}
      color="tint.orange"
    >
      <Box
        as="circle"
        class="progress-ring__circle"
        stroke="currentColor"
        strokeWidth={4}
        fill="white"
        r={size - 4}
        cx={size}
        cy={size}
        css={{
          transition: "stroke-dashoffset 0.35s",
          transform: "rotate(-90deg)",
          transformOrigin: "50% 50%",
        }}
        style={{
          strokeDasharray: `${circumference} ${circumference}`,
          strokeDashoffset: offset,
        }}
      />
    </Box>
  );
};

const ChallengeHero = ({
  logo,
  title,
  goal,
  goal_progress,
  goal_description,
  goal_units,
}) => (
  <>
    <Wrapper py="layout.7">
      <VStack align="center" space="layout.3">
        <Box width={192}>
          <AspectRatio ratio="1:1">
            <Ring size={96} progress={(goal_progress / goal) * 100} />
            <Image
              fluid={logo.fluid}
              alt={logo.alt}
              objectFit="contain"
              objectPosition="50% 50%"
              style={{
                position: "absolute",
                top: "10%",
                left: "10%",
                width: "80%",
                height: "80%",
              }}
            />
          </AspectRatio>
        </Box>
        <VStack align="center" space="layout.1">
          <Heading
            children={title.text}
            as="h1"
            size={900}
            textAlign="center"
          />

          <FundRaisingCard
            progress={goal_progress}
            goal={goal}
            units={goal_units}
          />
          <Paragraph
            children={goal_description}
            size={600}
            maxWidth={464}
            textAlign="center"
            color="text.1"
          />
        </VStack>
      </VStack>
    </Wrapper>
  </>
);

export default ChallengeHero;
